
.navbar {
    box-shadow: none;
    z-index: auto;
}
.navbar-top{
    padding-top: 20px;
}


.logo-title{
    font-family: Valenciana;
    font-size:30px;
    margin-bottom:0px ;
}

.button-flag{
    padding: 0;
    border: none;
    background: none;
}