.det-email{
    padding-left: 50px;
}



.det-address{
    padding-left: 10px;
}


.det-repo{
    padding-left: 30px;
}

.details{
    margin-right: 15px;
}

.lead{
    text-align: justify;
    text-justify: inter-word;
}