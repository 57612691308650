.description{
    text-align: left;
}

.description-text{
    box-shadow: 0 10px 30px -15px #b5ccff;;
    transition:all 0.25s cubic-bezier(0.645,0.045,0.355,1);
    position: relative;
    z-index: 2;
    padding: 25px;
    border-radius: 4px;
    background-color: #b5ccff;;
}
.align-button-show-site{
    text-align: right;
}
.view_site{
    text-align: center;
}
