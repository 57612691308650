* {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
}

html, body {
    height: 100vh;
    overflow: hidden;
}


h1{
    font-family: Valenciana;
}


h2, h3, h4, h5, p{
    font-family: AvenirRoman;
}


.title-name{
    font-size: 10vw;
    color:black;
    padding-bottom:0px;
}

.title{
    font-family: AvenirRoman;
    font-size: 3vw;
}

.subtitle{
    font-weight: 600;
}

.example {

    overflow-y: scroll; /* Add the ability to scroll */
  }
  

.example::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
	border-radius: 10px;
}

.example::-webkit-scrollbar
{
	width: 10px;
	background-color: #F5F5F5;
}

.example::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	background-image: -webkit-gradient(linear,
									   left bottom,
									   left top,
									   color-stop(0.44, rgb(122,153,217)),
									   color-stop(0.72, rgb(73,125,189)),
									   color-stop(0.86, rgb(28,58,148)));
}


.parents{
    height: 100vh;
}

section {
    scroll-snap-align: start; 
    position: relative;
}



@font-face {
    font-family: 'Valenciana';
    src: local('Valencia'), url(./fonts/Valencia.ttf) format('woff');
}

@font-face {
    font-family: 'Pacifico';
    src: local('Pacifico'), url(./fonts/Pacifico-Regular.ttf) format('woff');
}

@font-face {
    font-family: 'AvenirRoman';
    src: local('AvenirRoman'), url(./fonts/AvenirLTStd-Roman.otf) format('woff');
}

a.button.is-primary.is-outlined {
    border-width: 2px;
    font-weight: 400;
    font-size: 16px;
    padding: 0.7rem;
}

.button.is-primary.is-outlined {
    background-color: transparent;
    border-color: #333;
    color: #333;
    border: 1px solid ;
    text-decoration: none;
}

.button {
    font-family: "AvenirRoman", sans-serif;
    border-radius: 4px;
}

.button.is-primary.is-outlined:hover{
    background-color: #b5ccff;
    border-color: #333;
    color: #333;
}

.responsive-flex{
    display:flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.links{
    text-decoration: none;
    color:#212529;
}

a:hover {
    color: #212529 !important;
}